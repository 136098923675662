import { Button, HStack, Image, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import translationButtonImage from "../../assets/images/translationButtonImage.png";
import { translateContent } from "../../utils/api/social-talk.api";
import Loader from "./Spinner";

type TranslateButtonProps = {
  value: string;
  onChange: (value: string) => void;
};

const TranslateButton = ({ value, onChange }: TranslateButtonProps) => {
  const toast = useToast();
  const mutation = useMutation(translateContent);

  const translateMessage = () =>
    mutation.mutate(
      {
        payload: {
          text: value,
        },
      },
      {
        onSuccess: (data) => onChange(data?.data?.translated_text),
        onError: () => {
          toast({
            description: "Please try again.",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );

  return (
    <>
      {mutation.isLoading && <Loader />}
      <HStack>
        <Button
          variant="none"
          onClick={translateMessage}
          maxW="50px"
          w="100%"
          bg="#fff"
          // borderRadius="10px"
          // overflow={"hidden"}
        >
          <Image src={translationButtonImage} alt="translate" />
        </Button>
      </HStack>
    </>
  );
};

export default TranslateButton;
