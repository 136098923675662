import axios from "axios";
import { SERVICES } from "../../config";

export interface ApiResult {
  // Define your API response structure here
  data: any;
  headers: any;
  // Add other relevant fields
}
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Content-Type"] = "application/json";

//point person apis
export const assignPointPerson = async (payload: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload.contact_id}/assign_point_person`,
    payload.data
  );
export const getPointPerson = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/list_point_person/${queryKey[3]}`
  );

//document apis
export const getDocuments = async ({ queryKey }: any): Promise<ApiResult> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/contacts/${queryKey[2]}/documents?page=${queryKey[3]}&per_page=${queryKey[4]}&days=${queryKey[5]}`
  );
export const addDocument = async (payload: any) => {
  return await axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload.contact_id}/documents`,
    payload.data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};
export const deleteDocuments = async (payload: any): Promise<ApiResult> =>
  axios.delete(
    `${SERVICES.apiBaseUrl}/api/dealerships/${payload?.id}/contacts/${payload.contact_id}/documents/${payload.document_id}`
  );

export const createProcessSale = async (params: any): Promise<ApiResult> =>
  axios.post(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params?.id}/contacts/${params?.contact_id}/process_sales`,
    params.data
  );
export const getVehiclesbyStock = async (params: any): Promise<any> =>
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${params.dealershipId}/inventories/search?fields=stock_number&query=${params.searchStr}`
  );
export const getVehicleDetailByInventoryId = async ({ queryKey }: any): Promise<any> => 
  axios.get(
    `${SERVICES.apiBaseUrl}/api/dealerships/${queryKey[1]}/inventories/${queryKey[2]}`
  );