export const EMPTY = "";

// REGEX Expressions
export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const LINK_REGEX =
  /^(https?:\/\/)?([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(:\d{1,5})?(\/[a-zA-Z0-9#\/._-]+)?(\?[a-zA-Z0-9-_=&%]*)?(#[a-zA-Z0-9-_]*)?$/;

export const NAME_REGEX = /^(?![0-9])[a-zA-Z0-9\s]+$/;
export const FIRST_NAME_REGEX = /^[a-zA-Z0-9\s]+$/;

export const KEYPAD_DATA = [
  {
    value: "1",
    subValue: "",
  },
  {
    value: "2",
    subValue: "A B C",
  },
  {
    value: "3",
    subValue: "D E F",
  },
  {
    value: "4",
    subValue: "G H I",
  },
  {
    value: "5",
    subValue: "J K L",
  },
  {
    value: "6",
    subValue: "M N O",
  },
  {
    value: "7",
    subValue: "P Q R S",
  },
  {
    value: "8",
    subValue: "T U V",
  },
  {
    value: "9",
    subValue: "W X Y Z",
  },
  {
    value: "*",
    subValue: "",
  },
  {
    value: "0",
    subValue: "+",
  },
  {
    value: "#",
    subValue: "",
  },
];

export const RichTextEditorContainers = [
  ["bold", "italic", "underline"],
  [{ size: ["small", false, "large", "huge"] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ align: [] }],
  ["image", "link"],
];
export const RichTextEditorDocumentToolbar = [
  ["bold", "italic", "underline", "link"],
  [{ size: ["small", false, "large", "huge"] }],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ indent: "-1" }, { indent: "+1" }],
  [{ align: [] }],
];

export const VIDEO_UPLOAD_TEXT = `Greet from Simp Social, Here is your uploaded video`;
export const INVENTORY_UPLOAD_TEXT = `[FirstName] Please view the vehicle`;
export const APPOINTMENT_TEXT = `Schedule an appointment with me`;
export const CREDIT_TEXT = `Apply for credit here`;
export const INVENTORY_UPLOAD_VARIABLE = `[VehicleURL]`;
export const APPOINTMENT_VARIABLE = `[ScheduleApptLink]`;
export const CREDIT_VARIABLE = `[CreditAppLink]`;

export const defaultStyles = {
  control: {
    backgroundColor: "#fff",
    fontSize: 14,
    fontWeight: "normal",
    width: "100%",
  },

  "&multiLine": {
    control: {
      fontFamily: "monospace",
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: "1px solid transparent",
    },
    input: {
      padding: 9,
      // border: '1px solid silver',
      border: 0,
      outline: "none",
    },
  },

  "&singleLine": {
    display: "inline-block",
    width: 180,

    highlighter: {
      padding: 1,
      border: "2px inset transparent",
    },
    input: {
      padding: 1,
      // border: '2px inset',
      width: "100%",
      border: 0,
    },
  },

  suggestions: {
    list: {
      backgroundColor: "white",
      border: "1px solid rgba(0,0,0,0.15)",
      fontSize: 14,
    },
    item: {
      padding: "5px 15px",
      borderBottom: "1px solid rgba(0,0,0,0.15)",
      "&focused": {
        backgroundColor: "#cee4e5",
      },
    },
  },
};

export const defaultMentionStyles = {
  backgroundColor: "#cee4e5",
};

export const warmUpOptions = [
  { value: "after_hang_up", label: "After Hangup" },
  { value: "fifteen_seconds", label: "Fifteen Seconds" },
  { value: "thirty_seconds", label: "Thirty Seconds" },
];
