import { Box, Text } from "@chakra-ui/react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useUserInfo } from "../../../utils/hooks/useUserInfo";
import SwitchToggle from "../../common/Switch";
import { SearchAndFilterForm } from "./utils";
import { Input } from "../../common";

type CallsFilterProps = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  prefix?: string;
};

function CallsFilter({ control, setValue, prefix = "" }: CallsFilterProps) {
  const eventFields = [
    {
      label: "Value Calls",
      value: `${prefix}filter_and_sort.calls.value_calls`,
    },
    {
      label: "Missed Calls",
      value: `${prefix}filter_and_sort.calls.missed_calls`,
    },
    {
      label: "Outbound Calls",
      value: `${prefix}filter_and_sort.calls.outbound_calls`,
    },
    {
      label: "Ring To Voicemail",
      value: `${prefix}filter_and_sort.calls.ring_to_voicemail`,
    },
    {
      label: "Inbound Calls",
      value: `${prefix}filter_and_sort.calls.inbound_calls`,
    },
    {
      label: "Ring Less Voicemail",
      value: `${prefix}filter_and_sort.calls.ring_less_voicemail`,
    },
  ];

  const dialedIn = useWatch({
    control,
    name: `${prefix}filter_and_sort.calls.dialed_in`,
  });

  return (
    <>
      <Box background="var(--secondary-50)" padding="1.25rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Calls
        </Text>
      </Box>
      <Box padding="1.25rem" display="grid" gridTemplateColumns="1fr 1fr">
        {eventFields.map((eventField) => {
          return (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Box mb="1.5rem">
                    <SwitchToggle
                      label={eventField.label}
                      id={`#${eventField.label.split(" ").join("-")}`}
                      defaultChecked={value}
                      onChange={(checked: boolean) =>
                        setValue(eventField.value as any, checked)
                      }
                    />
                  </Box>
                );
              }}
              name={eventField.value as any}
              control={control}
              rules={{
                required: true,
              }}
            />
          );
        })}
      </Box>

      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box mb="1rem" paddingLeft="1.25rem">
              <SwitchToggle
                label="If dialed in the last X days or hours, will be removed"
                id="#last-response_calls"
                defaultChecked={value}
                onChange={(checked: boolean) => {
                  setValue(`${prefix}filter_and_sort.calls.dialed_in`, checked);
                  setValue(`${prefix}filter_and_sort.calls.x_hours`, "");
                  setValue(`${prefix}filter_and_sort.calls.x_days`, "");
                }}
              />
            </Box>
          );
        }}
        name={`${prefix}filter_and_sort.calls.dialed_in`}
        control={control}
        rules={{
          required: true,
        }}
      />

      {dialedIn && (
        <Box padding="1.25rem" display="grid" gridTemplateColumns="1fr 1fr">
          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box mb="1rem" mr="1rem">
                  <Input
                    label=""
                    type="number"
                    placeholder="X Days"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    w="100%"
                  />
                </Box>
              );
            }}
            name={`${prefix}filter_and_sort.calls.x_days`}
            control={control}
            rules={{
              required: true,
            }}
          />

          <Controller
            render={({ field: { onChange, value }, fieldState: { error } }) => {
              return (
                <Box mb="1rem">
                  <Input
                    label=""
                    type="number"
                    placeholder="X Hours"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                    w="100%"
                  />
                </Box>
              );
            }}
            name={`${prefix}filter_and_sort.calls.x_hours`}
            control={control}
            rules={{
              required: true,
            }}
          />
        </Box>
      )}
    </>
  );
}

export default CallsFilter;
