import { Box, HStack, Text } from "@chakra-ui/react";
import { useState } from "react";
import {
  Control,
  Controller,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import SwitchToggle from "../../common/Switch";
import DatePicker from "../../common/DateRangePicker";
import { CustomRadio } from "../../common";

type ContactDateProps = {
  control: Control<any>;
  setValue: UseFormSetValue<any>;
  prefix?: string;
};

function ContactDate({ control, setValue, prefix = "" }: ContactDateProps) {
  const allTime = useWatch({
    control,
    name: `${prefix}filter_and_sort.all_time`,
  });
  const datePicked = useWatch({
    control,
    name: `${prefix}filter_and_sort.datePicked`,
  });
  const sendOptions = [
    { label: " All Time", value: "all_time" },
    { label: "Custom Date Range", value: "date_range" },
  ];
  const [toggleValue, setToggleValue] = useState(datePicked?'date_range':"all_time");
  return (
    <>
      <Box padding="1rem">
        <CustomRadio
          options={sendOptions}
          value={toggleValue}
          setValue={(val) => {
            setToggleValue(val);
            if (val === "all_time") {
              setValue(`${prefix}filter_and_sort.all_time`, true);
              setValue(`${prefix}filter_and_sort.datePicked`, false);
              setValue(`${prefix}filter_and_sort.date_range`, {
                start_date: "",
                end_date: "",
              });
            } else {
              setValue(`${prefix}filter_and_sort.all_time`, false);
            }
          }}
        />
      </Box>
    
      {(toggleValue==='date_range') && (
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box padding="1rem">
                <DatePicker
                  label="Select Date Range"
                  value={{
                    startDate: value.start_date as Date,
                    endDate: value.end_date as Date,
                  }}
                  onChange={(range: { startDate: Date; endDate: Date }) => {
                    setValue(`${prefix}filter_and_sort.date_range`, {
                      start_date: range?.startDate,
                      end_date: range?.endDate,
                    });
                    setValue(
                      `${prefix}filter_and_sort.datePicked`,
                      !!range?.startDate
                    );
                  }}
                  w="100%"
                  shouldShowClear
                  isRange
                  isDisabled={allTime}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.date_range`}
          control={control}
          rules={{
            required: true,
          }}
        />
      )}
      <Box display="flex">
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box padding="1rem">
                <SwitchToggle
                label={"Apply On Created Date"}
                  id={`#created_date}`}
                  defaultChecked={value}
                  onChange={(checked: boolean) => {
                    setValue(`${prefix}filter_and_sort.created_date`, checked)
                    checked && setValue(`${prefix}filter_and_sort.association_date`, !checked)
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.created_date`}
          control={control}
          rules={{
            required: true,
          }}
        />
        <Controller
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <Box padding="1rem">
                <SwitchToggle
                  label={"Association Date"}
                  id={`#association_date}`}
                  defaultChecked={value}
                  onChange={(checked: boolean) => {
                    setValue(`${prefix}filter_and_sort.association_date`, checked)
                    checked && setValue(`${prefix}filter_and_sort.created_date`, !checked)
                  }}
                />
              </Box>
            );
          }}
          name={`${prefix}filter_and_sort.association_date`}
          control={control}
          rules={{
            required: true,
          }}
        />
      </Box>
    </>
  );
}

export default ContactDate;
