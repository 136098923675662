import {
  Box,
  Button,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Input, Select } from "../../../components/common";
import FileUploader from "../../../components/common/FileUploader";
import SwitchToggle from "../../../components/common/Switch";
import VoiceRecorder from "../../../components/common/VoiceRecorder";
import {
  getLineSettings,
  getTCPA,
  modifyLineSettings,
  postRecording,
} from "../../../utils/api/line-rings.api";
import AudioPlayer from "../../common/AudioPlayer";
import Loader from "../../common/Spinner";
import { InsertVariableDrawer } from "../../Drawers/InsertVariableDrawer";
const GreetAndRecord = () => {
  const { id, dealerId } = useParams();
  const toast = useToast();
  const mutation = useMutation(modifyLineSettings);
  const uploadMutation = useMutation(postRecording);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["greetandrecord", id, "greet_record_settings"],
    queryFn: getLineSettings,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setGeneralData(data?.data?.settings_hash);
      setAudioData(data?.data?.settings_hash?.greet_recording_url);
      setTranscribed(
        data?.data?.settings_hash?.greet_recording_url ? true : false
      );
    },
  });
  const { data: Tcpa } = useQuery({
    queryKey: ["tcpa", dealerId],
    queryFn: getTCPA,
    refetchOnWindowFocus: false,
    retry: false,
  });
  const [generalData, setGeneralData] = useState(data?.data?.settings_hash);

  const handleGreetRecord = (field: string, value: any) => {
    setGeneralData((prevState: any) => ({
      ...prevState,
      [field]: value,
    }));
  };
  const [showVariable, setShowVariable] = useState<boolean>(false);
  const [audioData, setAudioData] = useState<any>(
    generalData?.greet_recording_url ?? ""
  );
  const [transcribed, setTranscribed] = useState<boolean | undefined>(
    generalData?.greet_recording_url ? true : false
  );
  const [audioUpload, setAudioUpload] = useState(false);
  const options: SelectOptionProps[] = [
    { label: "Settings", value: "settings" },
    { label: "Steps", value: "steps" },
    { label: "Trigger", value: "trigger" },
    { label: "Leads", value: "leads" },
    { label: "Ledger", value: "ledger" },
    { label: "Create Copy", value: "create-copy" },
  ];
  const tcpa_options: SelectOptionProps[] = [
    { label: "Closed", value: "closed" },
    { label: "Appointment Scheduled", value: "appointment_scheduled" },
  ];
  const handleSubmit = async () => {
    if (audioData !== generalData?.greet_recording_url && audioData !== "") {
      const response = await fetch(audioData);
      const blob = await response.blob();
      let formData = new FormData();
      formData.append("audio_file", blob); // Append the File
      formData.append("attachment_type", "greet_call"); // Append other data as string
      uploadMutation.mutate(
        {
          id: id,
          dealerId,
          data: formData,
        },
        {
          onSuccess: (data) => {
            handleGreetRecord("greet_recording_url", data?.data?.presigned_url);
            setAudioUpload(true);
          },
          onError: (error: any) => {
            toast({
              description:
                error?.response?.data?.error ?? "Something went wrong ",
              status: "error",
              duration: 5000,
              isClosable: true,
              position: "top",
            });
          },
        }
      );
    } else {
      dataSubmit();
    }
  };
  const dataSubmit = () => {
    mutation.mutate(
      {
        id: id,
        data: {
          key: "greet_record_settings",
          settings_hash: generalData,
        },
        dealerId,
      },
      {
        onSuccess: () => {
          toast({
            description: "Settings updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          setAudioUpload(false);
        },
        onError: (error: any) => {
          toast({
            description:
              error?.response?.data?.error ?? "Something went wrong ",
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          setAudioUpload(false);
        },
      }
    );
  };
  useEffect(() => {
    if (audioUpload) {
      dataSubmit();
    }
  }, [audioUpload]);
  return (
    <VStack w="100%" alignItems="flex-start" position="relative">
      <Box
        paddingTop="0.25rem"
        height="calc(100vh - 235px)"
        overflow="auto"
        w="100%"
        pb="75px"
      >
        <VStack gap="1.5rem" alignItems="flex-start" marginTop="1.5rem">
          <HStack
            alignItems="flex-start"
            w="100%"
            position="relative"
            gap="1.5rem"
          >
            <Box
              width="1.25rem"
              height="1.25rem"
              background="var(--bluegray-200)"
              position="absolute"
              top="1rem"
              left="calc(25% - 9px)"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="0.5rem"
                height="0.5rem"
                background="var(--bluegray-50)"
                borderRadius="50%"
                position="relative"
                zIndex="2"
              ></Box>
            </Box>
            <Box
              width="0.125rem"
              height="calc(100% + 2.5rem)"
              background="var(--bluegray-200)"
              position="absolute"
              left="25%"
              top="1rem"
            ></Box>
            <VStack
              w="25%"
              alignItems="flex-start"
              position="relative"
              height="100%"
              mt="1rem"
            >
              <Text textStyle="h5" fontWeight="700">
                Call Starts
              </Text>
              <Text textStyle="h6" color="var(--grey-700)" maxW="18rem">
                Select the detailed settings when call starts
              </Text>
            </VStack>
            <VStack
              alignItems="flex-start"
              width="50%"
              gap="0"
              border=" 1px solid var(--grey-300)"
              background="white"
              borderRadius="0.5rem"
              padding="1.3rem"
            >
              <VStack alignItems="flex-start" width="100%" gap="1rem">
                <VStack alignItems="flex-start">
                  {/* <SwitchToggle
                    id="1"
                    label="Greet / Welcome Callers?"
                    onChange={(check: boolean) =>
                      handleGreetRecord("greet_caller", check)
                    }
                    defaultChecked={generalData?.greet_caller}
                  /> */}
                  <Text>Greet / Welcome Callers?</Text>
                  <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                    Welcome the caller to this Line using text-to-speech or
                    pre-recorded audio.
                  </Text>
                </VStack>

                <Box
                  padding="1rem"
                  background="var(--grey-50)"
                  borderRadius="0.5rem"
                  border="1px solid var(--grey-300)"
                  alignItems="flex-start"
                >
                  <Text textStyle="h6" fontWeight="600" mb="1rem">
                    You can record or upload an audio file for incoming phone
                    call notification.
                  </Text>
                  <Tabs
                    variant="primary"
                    index={generalData?.greet_type === "text_to_speech" ? 0 : 1}
                  >
                    <TabList mb="1rem" background="white">
                      <Tab
                        onClick={() =>
                          handleGreetRecord("greet_type", "text_to_speech")
                        }
                      >
                        Text To Speech
                      </Tab>
                      <Tab
                        onClick={() =>
                          handleGreetRecord("greet_type", "play_recording")
                        }
                      >
                        Recording
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel mb="1rem">
                        <Input
                          label="Recording Message"
                          type="text"
                          placeholder="Write here.."
                          onChange={(e) =>
                            handleGreetRecord(
                              "recording_message",
                              e.target.value
                            )
                          }
                          value={generalData?.recording_message}
                          mb="10px"
                        />

                        {!showVariable && (
                          <Button onClick={() => setShowVariable(true)}>
                            Add Variable
                          </Button>
                        )}

                        {showVariable && (
                          <InsertVariableDrawer
                            isOpen={showVariable}
                            isOnRight={true}
                            onClose={() => setShowVariable(false)}
                            onVariableSelect={(variable: any) =>
                              handleGreetRecord(
                                "recording_message",
                                `${generalData.recording_message} [${variable?.name}]`
                              )
                            }
                          />
                        )}
                      </TabPanel>
                      <TabPanel>
                        <Box
                          mb="1rem"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          background="white"
                          border="1px solid var(--grey-300)"
                          borderRadius="0.5rem"
                        >
                          <VoiceRecorder
                            transcribed={transcribed}
                            handleTranscribed={() => setTranscribed(true)}
                            handleAudioData={(src: string) => setAudioData(src)}
                          />
                          <FileUploader
                            transcribed={transcribed}
                            handleTranscribed={() => setTranscribed(true)}
                            handleAudioData={(src: string) => setAudioData(src)}
                          />
                        </Box>
                        {transcribed && audioData && (
                          <AudioPlayer
                            audioSrc={audioData}
                            transcribed={transcribed}
                            handleTranscribed={() => {
                              setTranscribed(false);
                              setAudioData("");
                            }}
                          />
                        )}
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </Box>
              </VStack>
            </VStack>
          </HStack>
          <HStack
            alignItems="flex-start"
            w="100%"
            position="relative"
            gap="1.5rem"
          >
            <Box
              width="1.25rem"
              height="1.25rem"
              background="var(--bluegray-200)"
              position="absolute"
              top="1rem"
              left="calc(25% - 9px)"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="0.5rem"
                height="0.5rem"
                background="var(--bluegray-50)"
                borderRadius="50%"
                position="relative"
                zIndex="2"
              ></Box>
            </Box>
            <Box
              width="0.125rem"
              height="calc(100% + 2.5rem)"
              background="var(--bluegray-200)"
              position="absolute"
              left="25%"
              top="1rem"
            ></Box>
            <Box
              width="0.125rem"
              height="calc(100% + 2.5rem)"
              background="var(--bluegray-200)"
              position="absolute"
              left="25%"
              top="1rem"
            ></Box>
            <VStack
              w="25%"
              alignItems="flex-start"
              position="relative"
              height="100%"
              mt="1rem"
            >
              <Text textStyle="h5" fontWeight="700">
                Call Recording
              </Text>
            </VStack>
            <VStack
              alignItems="flex-start"
              width="50%"
              gap="0"
              border=" 1px solid var(--grey-300)"
              background="white"
              borderRadius="0.5rem"
              padding="1.3rem"
            >
              <VStack alignItems="flex-start" width="100%" gap="1rem">
                {/* <VStack alignItems="flex-start">
                <SwitchToggle
                  id="1"
                  label="Should Record Calls?"
                  onChange={(check: boolean) =>
                    handleGreetRecord("record_calls", check)
                  }
                  defaultChecked={generalData?.record_calls}
                />
                <Text textStyle="h6" color="var(--grey-700)" maxW="39rem">
                  Welcome the caller to this Line using text-to-speech or
                  pre-recorded audio.
                </Text>
              </VStack> */}
                <VStack alignItems="flex-start">
                  <SwitchToggle
                    label="Inbound Calls"
                    onChange={(check: boolean) =>
                      handleGreetRecord("enable_inbound_settings", check)
                    }
                    defaultChecked={generalData?.enable_inbound_settings}
                  />
                </VStack>
                <VStack alignItems="flex-start">
                  <SwitchToggle
                    label="Outbound Calls"
                    onChange={(check: boolean) =>
                      handleGreetRecord("enable_outbound_settings", check)
                    }
                    defaultChecked={generalData?.enable_outbound_settings}
                  />
                </VStack>

                {/* 
              <Select
                label=""
                onChange={(value: any) => console.log(value)}
                placeholder="Always Record"
                options={textOptions}
                w="17rem"
                value="2"
                variant="default"
              /> */}
              </VStack>
            </VStack>
          </HStack>
          {/* <HStack
            alignItems="flex-start"
            w="100%"
            position="relative"
            gap="1.5rem"
          >
            <Box
              width="1.25rem"
              height="1.25rem"
              background="var(--bluegray-200)"
              position="absolute"
              top="1rem"
              left="calc(25% - 9px)"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                width="0.5rem"
                height="0.5rem"
                background="var(--bluegray-50)"
                borderRadius="50%"
                position="relative"
                zIndex="2"
              ></Box>
            </Box>
            <Box
              width="0.125rem"
              height="calc(100% - 1rem)"
              background="var(--bluegray-200)"
              position="absolute"
              left="25%"
              top="1rem"
            ></Box>
            <VStack
              w="25%"
              alignItems="flex-start"
              position="relative"
              height="100%"
              mt="1rem"
            >
              <Text textStyle="h5" fontWeight="700">
                TCPA: Text Authorization
              </Text>
            </VStack>
            <VStack
              alignItems="flex-start"
              width="50%"
              gap="0"
              border=" 1px solid var(--grey-300)"
              background="white"
              borderRadius="0.5rem"
              padding="1.3rem"
            >
              <VStack alignItems="flex-start" width="100%" gap="1rem">
                <VStack alignItems="flex-start">
                  <SwitchToggle
                    id="1"
                    label="Initial SMS Opt-In"
                    onChange={(check: boolean) =>
                      handleGreetRecord("initial_sms_opt_in", check)
                    }
                    defaultChecked={generalData?.initial_sms_opt_in}
                  />
                </VStack>
                <Input
                  type="text"
                  placeholder="Write here.."
                  onChange={(e) =>
                    handleGreetRecord("initial_sms_opt_message", e.target.value)
                  }
                  value={generalData?.initial_sms_opt_message}
                  isTextarea={true}
                />

                <VStack alignItems="flex-start">
                  <SwitchToggle
                    label=" 30-Day Opt-In"
                    onChange={(check: boolean) =>
                      handleGreetRecord("thirty_days_opt_in", check)
                    }
                    defaultChecked={generalData?.thirty_days_opt_in}
                  />
                </VStack>
                <Input
                  type="text"
                  placeholder="Write here.."
                  onChange={(e) =>
                    handleGreetRecord("thirty_days_opt_message", e.target.value)
                  }
                  value={generalData?.thirty_days_opt_message}
                  isTextarea={true}
                />
                <Select
                  label="TCPA disposition"
                  onChange={(value: any) =>
                    handleGreetRecord("tcpa_disposition", value.value)
                  }
                  options={
                    Tcpa?.data?.contact_dispositions?.map((tcpa: any) => ({
                      label: tcpa.name,
                      value: tcpa.id,
                    })) ?? []
                  }
                  w="17rem"
                  value={generalData?.tcpa_disposition}
                  variant="default"
                />
              </VStack>
            </VStack>
          </HStack> */}
        </VStack>
      </Box>
      <HStack
        bottom="0"
        w="100%"
        right="0"
        justifyContent="flex-end"
        padding="1rem"
        borderTop="1px solid var(--grey-300)"
        position="absolute"
        background="var(--grey-100)"
        zIndex="0"
      >
        <Button width="8rem" onClick={handleSubmit}>
          Save
        </Button>
      </HStack>
      {(isLoading || mutation.isLoading || uploadMutation.isLoading) && (
        <Loader />
      )}
    </VStack>
  );
};

export default GreetAndRecord;
