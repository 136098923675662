import { Box, Text } from "@chakra-ui/react";
import { Control, Controller, UseFormSetValue } from "react-hook-form";
import SwitchToggle from "../../common/Switch";
import { SearchAndFilterForm } from "./utils";

type EventsProps = {
  control: Control<SearchAndFilterForm>;
  setValue: UseFormSetValue<SearchAndFilterForm>;
  prefix?: string;
};

function Events({ control, setValue, prefix="" }: EventsProps) {
  const eventFields = [
    {
      label: "Future Scheduled Appointments",
      value: `${prefix}filter_and_sort.events.future_scheduled_appointments`,
    },
    {
      label: "Shown Appointments",
      value: `${prefix}filter_and_sort.events.shown_appointments`,
    },
    {
      label: "Missed Appointments",
      value: `${prefix}filter_and_sort.events.missed_appointments`,
    },
    { label: "Sold Deals", value: `${prefix}filter_and_sort.events.sold_deals` },
    { label: "Reminders", value: `${prefix}filter_and_sort.events.reminders` },
    { label: "Meetings", value: `${prefix}filter_and_sort.events.meetings` },

  ];
  return (
    <>
      <Box background="var(--secondary-50)" padding="1.25rem">
        <Text textStyle="h4" color="var(--grey-900)" fontWeight="600">
          Events
        </Text>
      </Box>
      <Box padding="1.25rem" display="grid" gridTemplateColumns="1fr 1fr">
        {eventFields.map((eventField) => {
          return (
            <Controller
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Box mb="1.5rem">
                    <SwitchToggle
                      label={eventField.label}
                      id={`#${eventField.label.split(" ").join("-")}`}
                      defaultChecked={value}
                      onChange={(checked: boolean) =>
                        setValue(eventField.value as any, checked)
                      }
                    />
                  </Box>
                );
              }}
              name={eventField.value as any}
              control={control}
              rules={{
                required: true,
              }}
            />
          );
        })}
      </Box>
    </>
  );
}

export default Events;
