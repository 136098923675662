import moment from "moment";
import * as yup from "yup";
import { isTimeBetween } from "../../../utils";
import {
  SearchAndFilterForm,
  defaultFilterValues,
} from "../../Drawers/SearchAndFilterDrawer/utils";

export type CreateBulkMessage = {
  name: string;
  filters_and_sort: SearchAndFilterForm["filter_and_sort"];
  broadcast_type: string;
  throttling_rate: string;
  filterApplied: boolean;
  content: string;
  broadcast_date: string;
  broadcast_time: string;
  from: any;
  reduce_count: number;
  working_hour_type: string;
  working_hour: {
    [key: string]: {
      day_status: boolean;
      start_time: string;
      end_time: string;
    };
  };
};

export const defaultValues = {
  name: "",
  filters_and_sort: defaultFilterValues["filter_and_sort"],
  broadcast_type: "send_now",
  throttling_rate: "two_hundred",
  filterApplied: false,
  content: "",
  broadcast_date: "",
  broadcast_time: "",
  from: "",
  reduce_count: 0,
  working_hour_type: "dealership",
  working_hour: {
    monday: { day_status: false, start_time: "", end_time: "" },
    tuesday: { day_status: false, start_time: "", end_time: "" },
    wednesday: { day_status: false, start_time: "", end_time: "" },
    thursday: { day_status: false, start_time: "", end_time: "" },
    friday: { day_status: false, start_time: "", end_time: "" },
    saturday: { day_status: false, start_time: "", end_time: "" },
    sunday: { day_status: false, start_time: "", end_time: "" },
  }
};

export const createBulkMessageValidation = (settingHours: any) => {
  return yup.object().shape({
    name: yup.string().required("Broadcast Name is required."),
    broadcast_type: yup.string().required("Schedule Type is required."),
    throttling_rate: yup.string().required("Throttling Type is required."),
    content: yup.string().required("Message Body is required.").max(1599),
    broadcast_date: yup
      .string()
      .test(
        "scheduleDate",
        "Schedule Date must be today or in future.",
        function (value) {
          const { broadcast_type } = this.parent;
          return broadcast_type !== "send_now"
            ? !!value && !moment(value).isBefore(moment(), "day")
            : true;
        }
      ),
      broadcast_time: yup
      .string()
      .when("broadcast_type", {
        is: (type: string) => type !== "send_now", // Validate only if type is not `send_now`
        then: yup
          .string()
          .required("Broadcast Time is required.")
          .test(
            "scheduleTime",
            "Schedule Time must be greater than the current time and within selected business hours.",
            function (value: any) {
              const { broadcast_date, working_hour, working_hour_type } = this.parent;

              // Get the day of the week (e.g., "monday", "tuesday")
              const currentDay = moment(broadcast_date).format("dddd").toLowerCase();
              const selectedDay = working_hour_type == "custom" ? 
                                  working_hour?.[currentDay] : settingHours[currentDay];

              // If the day is inactive or missing, validation fails
              if (!selectedDay || !selectedDay.day_status) {
                return false;
              }

              // Check if the time is within the valid range
              const isValidTime = isTimeBetween(
                selectedDay.start_time,
                selectedDay.end_time,
                value
              );

              // Validate the time against the current time
              return !!value && moment(broadcast_date + " " + value).isAfter(moment()) && isValidTime;
            }
          ),
        otherwise: yup.string(), // Skip validation if `broadcast_type` is `send_now`
      }),
    working_hour_type: yup.string(), // The new key to check working_hour
    // Conditionally apply the validation for `working_hour` based on `working_hour_type`
    working_hour:  yup.object(
      ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].reduce((acc, day) => {
        acc[day] = yup
          .object()
          .shape({
            day_status: yup.boolean(),
            start_time: yup
              .string()
              .when("day_status", {
                is: true,
                then: yup.string().required(`${day.charAt(0).toUpperCase() + day.slice(1)} Start Time is required.`),
              }),
            end_time: yup
              .string()
              .when("day_status", {
                is: true,
                then: yup
                  .string()
                  .required(`${day.charAt(0).toUpperCase() + day.slice(1)} End Time is required.`)
                  .test(
                    "is-greater-than-start",
                    `${day.charAt(0).toUpperCase() + day.slice(1)} End Time must be greater than Start Time.`,
                    function (value) {
                      const { start_time } = this.parent;
                      return start_time
                        ? moment(value, "HH:mm").isAfter(moment(start_time, "HH:mm"))
                        : true;
                    }
                  ),
              }),
          });
        return acc;
      }, {} as any)
    ),
  });
};
