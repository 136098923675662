import React, { useEffect, useState } from "react";
import { Box, Button, Flex, IconButton, Image, VStack,Tooltip } from "@chakra-ui/react";
import ArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Logo from "../../assets/logo-black.png";
import { SidebarMenu } from "../../config/constants/sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import { getPermissions } from "../../utils/api/usersetting.api";
import { useQuery } from "@tanstack/react-query";
import { useUserInfo } from "../../utils/hooks/useUserInfo";

const Sidebar = ({ permissionSetter }: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const [currentPath, setCurrentPath] = useState<string>();
  const [list, setList] = useState<any>();

  // const pathName = usePathname();

  useEffect(() => {
      setCurrentPath(location.pathname);
    if (location.pathname === "/leads/lead-details") {
      setIsCollapsed(true);
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };
  const userID = useUserInfo("id");
  const dealership = useUserInfo("dealership");

  const { data, isFetching: isLoading } = useQuery({
    queryKey: ["sidebar", userID, dealership?.id],
    queryFn: getPermissions,
    refetchOnWindowFocus: false,
    retry: false,
    onSuccess(data) {
      setList(data?.data?.permissions);
      permissionSetter(data?.data?.permissions);
    },
  });

  return (
    <Box
      minWidth={isCollapsed ? "4.25rem" : "16rem"}
      bg="white"
      // minH="100vh"
      borderRight="1px solid var(--grey-300, #E0E0E0)"
      transition="all 0.3s"
      position="relative"
      zIndex="2"
      p="1.5rem 0"
    >
      {/* Toggle Button */}
      <Flex
        w="full"
        justify="space-between"
        align="center"
        px="0.94rem"
        ml={isCollapsed ? "-0.2rem" : "initial"}
      >
        <Image
          src={Logo}
          alt="SimpSocial Logo"
          width="7.625rem"
          h="1.375rem"
          maxW={isCollapsed ? "0" : "7.625rem"}
        />
        <IconButton
          aria-label="Toggle Sidebar"
          variant="icon"
          bg={isCollapsed ? "grey.300" : "grey.100"}
          icon={isCollapsed ? <ArrowRightIcon /> : <ArrowLeftIcon />}
          onClick={toggleSidebar}
          color="grey.800"
          fontWeight={300}
        />
      </Flex>

      {/* Sidebar Content */}
      <VStack
        mt="1.5rem"
        spacing={0}
        height="calc(100vh - 100px)"
        overflowY="auto"
      >
        {SidebarMenu.filter((menuItem) => {
          const permission = list?.find(
            (perm: any) => perm.display_name === menuItem.name
          );
          return permission && permission.allowed;
        }).map((menuItem) => {
          return (
            <Flex
              key={menuItem?.id}
              align="center"
              w="full"
              borderBottom="1px solid var(--grey-300)"
              background={
                currentPath === menuItem?.path
                  ? "var(--primary-600)"
                  : "transparent"
              }
              style={
                menuItem?.allowed ? {} : { opacity: 0.5, pointerEvents: "none" }
              }
            >
                   <Tooltip
              key={menuItem.id}
              hasArrow
              label={menuItem.name}
              borderRadius="0.5rem"
              placement="right-end"
              background="var(--grey-900)"
              padding="0.5rem"
              fontSize="0.75rem"
              fontWeight="600"
              color="white"
              isDisabled={!isCollapsed}
            >
              <Button
                leftIcon={
                  <Box
                    as="span"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    padding="0.38rem"
                    border="1px solid var(--grey-300)"
                    borderRadius="0.5rem"
                    sx={{
                      svg: {
                        color:
                          currentPath === menuItem?.path
                            ? "white"
                            : "var(--grey-800)",
                        path: {
                          fill:
                            currentPath === menuItem?.path
                              ? "white"
                              : "var(--grey-800)",
                        },
                      },
                    }}
                  >
                    {menuItem?.icon}
                  </Box>
                }
                py="0.5rem"
                pl="1rem"
                w="full"
                onClick={() => {
                  navigate(menuItem.path);
                  setCurrentPath(menuItem.path);
                }}
                variant="none"
                display="flex"
                alignItems="center"
                fontSize="0.875rem"
                fontWeight={500}
                color={
                  currentPath === menuItem?.path ? "white" : "var(--grey-800)"
                }
              >
                {!isCollapsed ? menuItem?.name : ""}
              </Button>
              </Tooltip>
            </Flex>
          );
        })}
      </VStack>
    </Box>
  );
};

export default Sidebar;
