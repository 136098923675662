import {
  Box,
  Button,
  HStack,
  Text,
  Tooltip,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Select, Spinner } from "../../../../components/common";
import {
  BlockUserIcon,
  BroadcastIcon,
  ChatIcon,
  DNCIcon,
  EmptyIcon,
  InboundCallIcon,
  LastActiveSaleIcon,
  LastActivityIcon,
  MeetingCalendarIcon,
  PointPersonIcon,
  SettingsIcon,
  TagsIcon,
} from "../../../../components/icons/svg";
import {
  convertDateContacts,
  dealershipRoleChecker,
  getLastActivity,
  isInBusinessHours,
  simpAdminChecker,
} from "../../../../utils";
import {
  fetchContactsById,
  fetchEmailById,
  updateBlock,
  updateContactDisposition,
  updateDNC,
} from "../../../../utils/api/contact-details";
import { fetchSettings } from "../../../../utils/api/dealership-setting.api";
import { fetchDispositionDS } from "../../../../utils/api/disposition-settings.api";
import { fetchUserById } from "../../../../utils/api/users.api";
import {
  useHistoryDispatch,
  useHistoryState,
} from "../../../../utils/context/HistoryContext";
import { useTwilioState } from "../../../../utils/context/TwilioContext";
import { useUserInfo } from "../../../../utils/hooks/useUserInfo";
import Popup from "../../../Charts/Popup";
import EmailDrawer from "../../../Drawers/EmailDrawer";
import { InsertVariableDrawer } from "../../../Drawers/InsertVariableDrawer";
import OutboundCall from "../../../Drawers/OutboundCall";
import SmsDrawer from "../../../Drawers/SmsDrawer";
import { CreateAppointment } from "../../../MeetingCalendar/CreateAppointment";
import { AssignUnassignTags } from "./ContactSettings/AssignUnassignTags";
import ContactSettings from "./ContactSettings/ContactSettings";
import { EnrolledCampaignDrawer } from "./ContactSettings/EnrolledCampaignDrawer";
import InboundCall from "./InboundCall";
import { disableOpenPhone } from "../../../../utils/api/dealership-setting.api";

const ContactDetails = (props: any) => {
  const { id, refetch: parentRefetch } = props;
  const user = useUserInfo("user");
  const dealer = user?.dealership;
  const role = useUserInfo("user")?.role;
  let impSSRole = localStorage.getItem("currentUser")
    ? JSON.parse(localStorage.getItem("currentUser")!)?.impSSRole
    : "";

  const toast = useToast();
  const [isCPDrawerOpen, setIsCPDrawerOpen] = useState<boolean>(false);
  const [isEmailCenterOpen, setIsEmailCenterOpen] = useState<boolean>(false);
  const [isSmsCenterOpen, setIsSmsCenterOpen] = useState<boolean>(false);
  const [isExpandChat, setIsExpandChat] = useState<boolean>(true);
  const [isVariableOpen, setIsVariableOpen] = useState<boolean>(false);
  const [isTemplateOpen, setIsTemplateOpen] = useState<boolean>(false);
  const [isOutBoundCall, setIsOutBoundCall] = useState<boolean>(false);
  const [isInBoundCall, setIsInBoundCall] = useState<boolean>(false);
  const [isAppointmentOpen, setIsAppointmentOpen] = useState<boolean>(false);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isOpenTags,
    onClose: onCloseTags,
    onOpen: onOpenTags,
  } = useDisclosure();
  const { openSMS, openAppointment } = useTwilioState();

  const [isOpenDNCAlert, setIsOpenDNCAlert] = useState(false);
  const [isOpenBlockAlert, setIsOpenBlockAlert] = useState(false);
  const onCloseDNCAlert = () => setIsOpenDNCAlert(false);
  const onCloseBlockAlert = () => setIsOpenBlockAlert(false);

  const [clickedRow, setClickedRow] = useState<any>();

  const dispatch = useHistoryDispatch();
  const { shouldRefetchDetail } = useHistoryState();
  const { devices } = useTwilioState();

  const location = useLocation();
  let [searchParams] = useSearchParams();
  const contact_id = location?.state?.contact_id;
  const contactId = searchParams.get("contact_id") ?? contact_id;

  const { isLoading: isLoadingContact, refetch } = useQuery({
    queryKey: ["fetchContactById", id],
    queryFn: () =>
      fetchContactsById(
        dealer?.id,
        contactId && contactId !== "undefined" ? contactId : id
      ),
    refetchOnWindowFocus: false,
    retry: false,
    enabled: id !== undefined,
    onSuccess(data) {
      setClickedRow(data?.data);
      // props.setClickedRecord && props.setClickedRecord(data?.data);
    },
  });

  const { data: userObj } = useQuery({
    queryKey: ["userById"],
    queryFn: () => fetchUserById(user?.id),
  });

  const { isLoading: isLoadingEmail, data: userEmailData } = useQuery({
    queryKey: ["fetchUserEmail", id],
    queryFn: () => fetchEmailById(dealer?.id),
    refetchOnWindowFocus: false,
    enabled: id !== undefined,
    retry: false,
  });
  const { data: isOpenPhone } = useQuery({
    queryKey: ["openPhone", user?.dealership?.id],
    queryFn: disableOpenPhone,
    refetchOnWindowFocus: false,
    retry: false,
  });

  useEffect(() => {
    if (shouldRefetchDetail) {
      refetch();
    }
    dispatch({
      type: "SET_SHOULD_REFETCH_DETAIL",
      payload: { shouldRefetchDetail: false },
    });
  }, [dispatch, refetch, shouldRefetchDetail]);

  const { data: emailData } = userEmailData || {};
  const { email: userEmail } = emailData?.[0] || {};
  const isEmailEnabled =
    !!userEmail &&
    userObj?.permissions?.some(
      (permission: any) =>
        permission?.module_key === "emails" && permission?.allowed
    );

  const mutation = useMutation(updateContactDisposition);

  const handleDNCMutation = () => {
    mutationDNC.mutate({
      contact: {
        dnc: !clickedRow?.dnc,
      },
    });
    setIsOpenDNCAlert(false);
  };

  const handleBlockMutation = () => {
    mutationBlock.mutate({
      contact: {
        block: !clickedRow?.block,
      },
    });
    setIsOpenBlockAlert(false);
  };

  const mutationDNC = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateDNC(
          { dId: dealer?.id, id: clickedRow?.id },
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "DNC updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    },
    onError: (error: any) => {
      refetch();
      toast({
        description: `Error updating DNC: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const mutationBlock = useMutation<any, Error, any, unknown>({
    mutationFn: async (payload) => {
      try {
        const response = await updateBlock(
          { dId: dealer?.id, id: clickedRow?.id },
          payload
        );
        return { data: response.data };
      } catch (error) {
        throw error;
      }
    },
    onSuccess: () => {
      refetch();
      toast({
        description: "Block updated successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      dispatch({
        type: "SET_SHOULD_REFETCH",
        payload: { shouldRefetch: true },
      });
    },
    onError: (error: any) => {
      refetch();
      toast({
        description: `Error updating Block: ${
          error?.response?.data?.errors?.toString() ?? "Something went wrong"
        }`,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });

  const getDisableValue = () => {
    if (simpAdminChecker(impSSRole)) {
      return false;
    }
    return (
      clickedRow?.dnc &&
      (dealershipRoleChecker(role) || impSSRole === "Customer Service Agent")
    );
  };

  const iconButtons = [
    {
      id: 2,
      tooltip: !isOpenPhone?.data?.within_hours
        ? "Outside business hours, outbound messages are not permitted."
        : devices.length
        ? "Message"
        : "No Line Exists!",
      icon: <ChatIcon />,
      onclick: () => setIsSmsCenterOpen(true),
      isDisabled: !(devices.length && isOpenPhone?.data?.within_hours),
    },
    {
      id: 4,
      tooltip: !isOpenPhone?.data?.within_hours
        ? "Outside business hours, outbound calls are not permitted."
        : devices.length
        ? "Outbound Call"
        : "No Line Exists!",
      icon: <CallIcon />,
      isDisabled: clickedRow?.dnc || !(devices.length && isOpenPhone?.data?.within_hours),
      active: isOutBoundCall,
      onclick: () => setIsOutBoundCall(true),
    },
    {
      id: 1,
      tooltip: isEmailEnabled ? "Email" : "No Registered Email",
      icon: (
        <EmailIcon
          style={{
            width: "1.75rem",
            height: "1.75rem",
            color: "var(--grey-800)",
          }}
        />
      ),
      active: isEmailCenterOpen,
      onclick: () => setIsEmailCenterOpen(true),
      isDisabled: !isEmailEnabled,
    },

    {
      id: 3,
      tooltip: "Inbound Call",
      icon: <InboundCallIcon />,
      active: isInBoundCall,
      onclick: () => setIsInBoundCall(true),
    },
    {
      id: 6,
      tooltip: "Appointment",
      icon: <MeetingCalendarIcon />,
      active: isAppointmentOpen,
      onclick: () => {
        setIsAppointmentOpen(true);
      },
    },
    {
      id: 5,
      tooltip: "Block",
      icon: <BlockUserIcon />,
      active: clickedRow?.block,
      onclick: () => {
        setIsOpenBlockAlert(true);
      },
    },
    {
      id: 6,
      tooltip: "DNC",
      icon: <DNCIcon />,
      active: clickedRow?.dnc,
      onclick: () => {
        setIsOpenDNCAlert(true);
      },
      isDisabled: getDisableValue(),
    },
  ];

  const { data, isLoading } = useQuery({
    queryKey: ["fetchDispositionDS"],
    queryFn: () => fetchDispositionDS(dealer?.id, { status: "active" }),
    retry: false,
    enabled: id !== undefined,
    refetchOnWindowFocus: false,
  });

  const hanleDispsitionChange = (value: any) => {
    mutation.mutate(
      {
        dId: dealer?.id,
        id,
        payload: { contact_dispositions_ids: value },
      },
      {
        onSuccess: () => {
          refetch();
          toast({
            description: "Contact updated successfully.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          // parentRefetch()
        },
        onError: (error: any) => {
          toast({
            description: `Error updating contact: ${
              error?.response?.data?.errors?.toString() ??
              "Something went wrong"
            }`,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        },
      }
    );
  };

  if (id) {
    return (
      <VStack
        alignItems="flex-start"
        padding="1.25rem"
        background="var(--grey-50)"
        w="32%"
        gap="1rem"
        borderRight="1px solid var(--grey-300)"
        overflow="auto"
        height="calc(100vh - 100px)"
      >
        <HStack justifyContent="space-between" w="100%">
          {/* <Image
            src={
              clickedRow?.id
                ? `https://source.unsplash.com/random/1920x1080/?abstract,pattern&${clickedRow?.id}`
                : Avatar
            }
            width="48px"
            height="48px"
            alt="avatar"
            style={{
              border: "2px solid var(--secondary-600)",
              borderRadius: "50%",
            }}
          /> */}
          <AccountCircleIcon
            style={{
              width: "48px",
              height: "48px",
            }}
          />

          <Button
            variant="none"
            background="white"
            border="1px solid var(--grey-300)"
            borderRadius="0.5rem"
            padding="0.37rem"
            onClick={() => setIsCPDrawerOpen(true)}
          >
            <SettingsIcon />
          </Button>
        </HStack>
        <HStack
          alignItems="center"
          justifyContent="space-between"
          w="100%"
          gap="0.75rem"
        >
          <VStack gap="0.38rem" alignItems="flex-start">
            <Text textStyle="h3" fontWeight="600" color="var(--grey-800)">
              {clickedRow?.use_nick_name && `${clickedRow?.nick_name}`}
              {!clickedRow?.use_nick_name &&
                `${clickedRow?.first_name || ""} ${
                  clickedRow?.last_name || ""
                }`}
            </Text>
            <Text textStyle="h5" fontWeight="400" color="var(--grey-800)">
              {clickedRow?.phone_number || ""}
            </Text>
            <Text textStyle="h5" fontWeight="400" color="var(--grey-800)">
              {clickedRow?.email || ""}
            </Text>
          </VStack>
        </HStack>
        <HStack flexWrap="wrap">
          {iconButtons?.slice(0, 4)?.map((item) => (
            <Tooltip
              key={item.id}
              hasArrow
              label={item.tooltip}
              borderRadius="0.5rem"
              placement="top"
              background="var(--grey-900)"
              padding="0.5rem"
              fontSize="0.75rem"
              fontWeight="600"
              color="white"
            >
              <Button
                variant="none"
                display="flex"
                isDisabled={item.isDisabled}
                sx={{
                  svg: {
                    width:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    height:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    color: item.active ? "white" : "var(--grey-900)",
                    path: {
                      fill: item.active ? "white" : "grey.800",
                    },
                  },
                  background: item.active
                    ? "var(--primary-600)"
                    : "transparent",
                  _hover: {
                    background: "var(--primary-600)",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                alignItems="center"
                justifyContent="center"
                padding="0.5rem"
                background="white"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                onClick={item.onclick}
              >
                {item.icon}
              </Button>
            </Tooltip>
          ))}
        </HStack>
        <HStack flexWrap="wrap">
          {iconButtons?.slice(4)?.map((item) => (
            <Tooltip
              key={item.id}
              hasArrow
              label={item.tooltip}
              borderRadius="0.5rem"
              placement="top"
              background="var(--grey-900)"
              padding="0.5rem"
              fontSize="0.75rem"
              fontWeight="600"
              color="white"
            >
              <Button
                isDisabled={item.isDisabled}
                variant="none"
                display="flex"
                sx={{
                  svg: {
                    width:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    height:
                      item.tooltip === "Powerdialer" ? "1.35rem" : "1.75rem",
                    color: item.active ? "white" : "var(--grey-900)",
                    path: {
                      fill: item.active ? "white" : "grey.800",
                    },
                  },
                  background: item.active
                    ? item.tooltip === "Appointment"
                      ? "var(--primary-600)"
                      : "red"
                    : "transparent",
                  _hover: {
                    background:
                      item.tooltip === "Appointment"
                        ? "var(--primary-600)"
                        : "red",
                    svg: {
                      color: "white",
                      path: {
                        fill: "white",
                      },
                    },
                  },
                }}
                alignItems="center"
                justifyContent="center"
                padding="0.5rem"
                background="white"
                border="1px solid var(--grey-300)"
                borderRadius="0.5rem"
                onClick={item.onclick}
              >
                {item.icon}
              </Button>
            </Tooltip>
          ))}
        </HStack>
        <HStack
          w="100%"
          gap="0.62rem"
          alignItems="flex-start"
          padding="1rem"
          background="var(--secondary-50)"
          borderRadius="0.5rem"
        >
          <BroadcastIcon color="var(--secondary-600)" />
          <VStack gap="0.5rem" mt="-0.15rem" alignItems="flex-start">
            <Text fontSize="1rem" fontWeight="600">
              Campaign
            </Text>
            <Button
              variant="none"
              color="var(--orange-800)"
              padding="0.5rem 1rem"
              border="1px solid var(--orange-800)"
              borderRadius="0.5rem"
              onClick={onOpen}
            >
              Enroll/Disenroll From Campaigns
            </Button>
          </VStack>
        </HStack>
        <HStack
          w="100%"
          gap="0.62rem"
          alignItems="flex-start"
          padding="1rem"
          background="var(--secondary-50)"
          borderRadius="0.5rem"
        >
          <TagsIcon color="var(--secondary-600)" />
          <VStack gap="0.5rem" mt="-0.15rem" alignItems="flex-start">
            <Text fontSize="1rem" fontWeight="600">
              Tags
            </Text>
            <Button
              variant="none"
              color="var(--orange-800)"
              padding="0.5rem 1rem"
              border="1px solid var(--orange-800)"
              borderRadius="0.5rem"
              onClick={onOpenTags}
            >
              Assign/Unassign Tags
            </Button>
          </VStack>
        </HStack>

        <Select
          label="Contact Disposition Status"
          isMulti={true}
          onChange={(value: any) => {
            hanleDispsitionChange(value?.map?.((i: any) => i.value));
          }}
          placeholder="Select Disposition Status"
          options={data?.contact_dispositions?.map((item: any) => ({
            label: item.name,
            value: item.id,
          }))}
          variant="default"
          w="100%"
          value={clickedRow?.contact_dispositions?.map((i: any) => ({
            label: i.name,
            value: i.id,
          }))}
        />
        <VStack alignItems="flex-start" gap="1rem">
          <HStack alignItems="flex-start">
            <VStack alignSelf="stretch" gap="0.3rem">
              <Box
                as="span"
                padding="0.45rem"
                border="1px solid var(--grey-300)"
                background="white"
                borderRadius="0.5rem"
              >
                <LastActivityIcon />
              </Box>
              <VStack height="100%" gap="0">
                <Box
                  as="span"
                  padding="0.25rem"
                  background="var(--grey-300)"
                  borderRadius="50%"
                ></Box>
                <Box
                  as="span"
                  w="1px"
                  height="100%"
                  background="var(--grey-300)"
                ></Box>
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" gap="0.56rem" pt="0.63rem">
              <Text textStyle="h4" fontWeight="600">
                Last Activity
              </Text>
              <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                {convertDateContacts(
                  clickedRow?.last_activity?.created_at,
                  "ddd MMM D, YYYY h:mma"
                )}
              </Text>
              <Text textStyle="h6" fontWeight="500">
                {getLastActivity(clickedRow?.last_activity)}
              </Text>
            </VStack>
          </HStack>
          <HStack alignItems="flex-start">
            <VStack alignSelf="stretch" gap="0.3rem">
              <Box
                as="span"
                padding="0.45rem"
                border="1px solid var(--grey-300)"
                background="white"
                borderRadius="0.5rem"
              >
                <PointPersonIcon />
              </Box>
              <VStack height="100%" gap="0">
                <Box
                  as="span"
                  padding="0.25rem"
                  background="var(--grey-300)"
                  borderRadius="50%"
                ></Box>
                <Box
                  as="span"
                  w="1px"
                  height="100%"
                  background="var(--grey-300)"
                ></Box>
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" gap="0.56rem" pt="0.63rem">
              <VStack alignItems="flex-start" gap="0.25rem">
                <Text textStyle="h4" fontWeight="600">
                  Point Person
                </Text>
              </VStack>
              {clickedRow?.assign_person?.sales_person?.name && (
                <>
                  <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                    Sales Person
                  </Text>
                  <Text textStyle="h6" fontWeight="500">
                    {clickedRow?.assign_person?.sales_person?.name}
                  </Text>
                </>
              )}

              {clickedRow?.assign_person?.manager?.name && (
                <>
                  <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                    Manager
                  </Text>
                  <Text textStyle="h6" fontWeight="500">
                    {clickedRow?.assign_person?.manager?.name}
                  </Text>
                </>
              )}

              {clickedRow?.assign_person?.bdc_agent?.name && (
                <>
                  <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                    BDC Agent
                  </Text>
                  <Text textStyle="h6" fontWeight="500">
                    {clickedRow?.assign_person?.bdc_agent?.name}
                  </Text>
                </>
              )}
            </VStack>
          </HStack>
          <HStack alignItems="flex-start">
            <VStack alignSelf="stretch" gap="0.3rem">
              <Box
                as="span"
                padding="0.45rem"
                border="1px solid var(--grey-300)"
                background="white"
                borderRadius="0.5rem"
              >
                <LastActiveSaleIcon />
              </Box>
              <VStack height="100%" gap="0">
                <Box
                  as="span"
                  padding="0.25rem"
                  background="var(--grey-300)"
                  borderRadius="50%"
                ></Box>
                <Box
                  as="span"
                  w="1px"
                  height="100%"
                  background="var(--grey-300)"
                ></Box>
              </VStack>
            </VStack>
            <VStack alignItems="flex-start" gap="0.56rem" pt="0.63rem">
              <VStack alignItems="flex-start" gap="0.25rem">
                <Text textStyle="h4" fontWeight="600">
                  Last Active Sales Lead
                </Text>
                <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                  {convertDateContacts(
                    clickedRow?.latest_active_lead?.created_at,
                    "ddd MMM D, YYYY h:mma"
                  )}
                </Text>
              </VStack>
              {clickedRow?.latest_active_lead?.vehicle && (
                <Text textStyle="h6" fontWeight="500">
                  {clickedRow?.latest_active_lead?.vehicle?.make} -{" "}
                  {clickedRow?.latest_active_lead?.vehicle?.model} -{" "}
                  {clickedRow?.latest_active_lead?.vehicle?.year}
                </Text>
              )}
              <Text textStyle="h6" fontWeight="400" color="var(--grey-600)">
                {clickedRow?.latest_active_lead?.lead_source}
              </Text>
            </VStack>
          </HStack>
        </VStack>
        {isEmailCenterOpen && (
          <EmailDrawer
            isOpen={isEmailCenterOpen}
            onClose={() => setIsEmailCenterOpen(false)}
            clickedRow={clickedRow}
            userEmail={userEmail}
          />
        )}
        {(openSMS || isSmsCenterOpen) && (
          <SmsDrawer
            isOpen={isSmsCenterOpen}
            onClose={() => setIsSmsCenterOpen(false)}
            clickedRow={clickedRow}
          />
        )}
        {/* Variable Drawer */}
        <InsertVariableDrawer
          isOpen={isVariableOpen}
          onClose={() => setIsVariableOpen(false)}
        />
        {/* Inbound Call Drawer */}
        {isInBoundCall && (
          <InboundCall
            isExpandChat={isExpandChat}
            setIsExpandChat={setIsExpandChat}
            setIsInBoundCall={setIsInBoundCall}
            setIsAppointmentOpen={setIsAppointmentOpen}
            clickedRow={clickedRow}
            refetchParent={refetch}
          />
        )}
        {isOutBoundCall && (
          <OutboundCall
            isOpen={isOutBoundCall}
            onClose={() => setIsOutBoundCall(false)}
            clickedRow={clickedRow}
          />
        )}
        {isOpen && (
          <EnrolledCampaignDrawer
            isOpen={isOpen}
            onClose={onClose}
            clickedRow={clickedRow}
            dealership_id={dealer?.id}
          />
        )}
        {isOpenTags && (
          <AssignUnassignTags
            isOpen={isOpenTags}
            onClose={onCloseTags}
            clickedRow={clickedRow}
            dealership_id={dealer?.id}
          />
        )}
        {isCPDrawerOpen && (
          <ContactSettings
            isCPDrawerOpen={isCPDrawerOpen}
            onCPClose={() => {
              setIsCPDrawerOpen(false);
              refetch();
              // parentRefetch();
            }}
            id={clickedRow?.id}
            refetchParent={refetch}
          />
        )}
        {isOpenDNCAlert && (
          <Popup
            isOpen={true}
            onClose={onCloseDNCAlert}
            onConfirm={handleDNCMutation}
            title="DNC Confirmation"
            message="Are you sure you want to update DNC?"
          />
        )}
        {isOpenBlockAlert && (
          <Popup
            isOpen={true}
            onClose={onCloseBlockAlert}
            onConfirm={handleBlockMutation}
            title="Block Confirmation"
            message={
              !clickedRow?.block
                ? "Are you sure you want to block this contact?"
                : "Are you sure you want to unblock this contact?"
            }
          />
        )}
        {(isAppointmentOpen || openAppointment) && (
          <CreateAppointment
            isOpen={isAppointmentOpen}
            onClose={() => setIsAppointmentOpen(false)}
            contactid={clickedRow?.id}
            apiCall={() => {
              refetch();
              dispatch({
                type: "SET_SHOULD_REFETCH",
                payload: { shouldRefetch: true },
              });
            }}
            slotData={{ type: { value: "appointment" } }}
          />
        )}

        {(mutationBlock?.isLoading ||
          mutationDNC?.isLoading ||
          isLoading ||
          mutation.isLoading ||
          isLoadingEmail) && <Spinner />}
      </VStack>
    );
  } else {
    return (
      <VStack w="100%" height="700px" justifyContent="center">
        <EmptyIcon />
        <Text textStyle="h5" fontWeight="400" color="var(--grey-500)">
          Oops! Looks like there's no data at the moment.
        </Text>
      </VStack>
    );
  }
};

export default ContactDetails;
