import * as yup from "yup";
import { fetchDispositionDS } from "../../../utils/api/disposition-settings.api";
import {
  fetchBatch,
  fetchLeadSource,
  fetchTags,
} from "../../../utils/api/global-config.api";
import { getInfiniteLines } from "../../../utils/api/line-rings.api";
import { fetchRoles, fetchUsersDP } from "../../../utils/api/users.api";

export type SearchAndFilterForm = {
  filter_and_sort: {
    date_range: {
      start_date: Date | string;
      end_date: Date | string;
    };
    all_time: boolean;
    created_date: boolean;
    datePicked: boolean;
    contact_attributes: {
      created_at: Date | string;
      disposition_status?: string[];
      lead_source?: string[];
      tags?: string[];
      roles?: string[];
      users?: string[];
      lines?: string[];
      batch_id?: string[];
    };
    events: {
      has_appointments: boolean;
      has_reminders: boolean;
      future_scheduled_appointments: boolean;
      shown_appointments: boolean;
      missed_appointments: boolean;
      sold_deals: boolean;
      reminders: boolean;
      meetings: boolean;
    };
    calls: {
      value_calls: boolean;
      missed_calls: boolean;
      outbound_calls: boolean;
      ring_to_voicemail: boolean;
      dialed_in: boolean;
      inbound_calls: boolean;
      ring_less_voicemail: boolean;
      x_hours: string;
      x_days: string;
    };
    contact_engagement: {
      dialed_in: boolean;
      x_hours: string;
      x_days: string;
      no_outbound_activity: boolean;
      no_inbound_activity: boolean;
      has_unread_messages: boolean;
      unassigned_leads: boolean;
      new_leads: boolean;
      enrolled_in_campaigns: boolean;
    };
    restricted_contacts: {
      unsubscribed: boolean;
      dnc: boolean;
      block: boolean;
    };
  };
};

export const defaultFilterValues = {
  filter_and_sort: {
    date_range: {
      start_date: "",
      end_date: new Date(""),
    },
    all_time: false,
    created_date: false,
    datePicked: false,
    contact_attributes: {
      created_at: "",
      disposition_status: undefined,
      lead_source: undefined,
      tags: undefined,
      roles: undefined,
      users: undefined,
      lines: undefined,
      batch_id: undefined,
    },
    events: {
      has_appointments: false,
      has_reminders: false,
      future_scheduled_appointments: false,
      shown_appointments: false,
      missed_appointments: false,
      sold_deals: false,
      reminders: false,
      meetings:false,
    },
    calls: {
      value_calls: false,
      missed_calls: false,
      outbound_calls: false,
      ring_to_voicemail: false,
      dialed_in: false,
      inbound_calls: false,
      ring_less_voicemail: false,
      x_hours: "",
      x_days: "",
    },
    contact_engagement: {
      dialed_in: false,
      x_hours: "",
      x_days: "",
      has_outbound_emails: false,
      has_outbound_messages: false,
      no_outbound_activity: false,
      no_inbound_activity: false,
      has_unread_messages: false,
      unassigned_leads: false,
      new_leads: false,
      enrolled_in_campaigns: false,
    },
    restricted_contacts: {
      unsubscribed: false,
      dnc: false,
      block: false,
    },
  },
};

export const searchAndFilterValidation: any = yup.object().shape({
  filter_and_sort: yup.object().shape({
    calls: yup.object().shape({
      dialed_in: yup.boolean(),
      x_hours: yup
        .string()
        .when("dialed_in", {
          is: (dialedIn: boolean) => dialedIn,
          then: yup
            .string()
            .required("X Hours is required.")
            .max(3, "Max 3 characters allowed"),
          otherwise: yup.string().max(3, "Max 3 characters allowed"),
        })
        .max(3, "Max 3 characters allowed"),
      x_days: yup
        .string()
        .when("dialed_in", {
          is: (dialedIn: boolean) => dialedIn,
          then: yup
            .string()
            .required("X Days is required.")
            .max(3, "Max 3 characters allowed"),
          otherwise: yup.string().max(3, "Max 3 characters allowed"),
        })
        .max(3, "Max 3 characters allowed"),
    }),
    contact_engagement: yup.object().shape({
      dialed_in: yup.boolean(),
      x_hours: yup
        .string()
        .when("dialed_in", {
          is: (dialedIn: boolean) => dialedIn,
          then: yup
            .string()
            .required("X Hours is required.")
            .max(3, "Max 3 characters allowed"),
          otherwise: yup.string().max(3, "Max 3 characters allowed"),
        })
        .max(3, "Max 3 characters allowed"),
      x_days: yup
        .string()
        .when("dialed_in", {
          is: (dialedIn: boolean) => dialedIn,
          then: yup
            .string()
            .required("X Days is required.")
            .max(3, "Max 3 characters allowed"),
          otherwise: yup.string().max(3, "Max 3 characters allowed"),
        })
        .max(3, "Max 3 characters allowed"),
    }),
  }),
});

export const loadLeadSource = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchLeadSource({ q: searchStr }, id);
    let options = response?.lead_sources?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.name,
    }));

    options = !options.length
      ? [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Lead Source" },
        ]
      : [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Lead Source" },
          { value: "imported", label: "Imported" },
          { value: "manually_entered", label: "Manually Entered" },
          ...options,
        ];

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadBatch = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchBatch({ q: searchStr }, id);
    let options = response?.import_contacts?.map((leadSource: any) => ({
      value: leadSource.id,
      label: leadSource.name,
    }));

    options = !options.length
      ? [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Batch" },
        ]
      : [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Batch" },
          ...options,
        ];

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadRoles = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchRoles(false);
    const options = response?.roles
      ?.filter((item: any) => item?.title !== "Owner/GM")
      ?.map((role: any) => ({
        value: role.id,
        label: role.title,
      }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadUsers = async (
  searchStr: string,
  id?: string,
  mappedRoles?: string
) => {
  try {
    const response = await fetchUsersDP({
      searchValue: searchStr,
      dealership_id: id,
      status: "active",
      role_titles: mappedRoles
        ? mappedRoles
        : "Manager/Admin,Sales Person,BDC Agent",
    });
    const options = response?.users?.map((user: any) => ({
      value: user.id,
      label: user.full_name,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadTags = async (searchStr: string, id?: string) => {
  try {
    const response = await fetchTags({ q: searchStr }, id);
    let options = response?.tags?.map((tag: any) => ({
      value: tag.id,
      label: tag.name,
    }));

    options = !options.length
      ? [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Tags" },
        ]
      : [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Tags" },
          ...options,
        ];

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadDispositionSettings = async (
  searchStr: string,
  id?: string
) => {
  try {
    const response = await fetchDispositionDS(id || "", { q: searchStr });
    let options =
      response?.contact_dispositions?.map((contactDisposition: any) => ({
        value: contactDisposition.id,
        label: contactDisposition.name,
      })) || [];

    options = !options.length
      ? [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Disposition" },
        ]
      : [
          { value: "all", label: "Select All" },
          { value: "none", label: "No Disposition" },
          ...options,
        ];

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const loadLines = async (searchStr: string, id?: string) => {
  try {
    const response = await getInfiniteLines({ dealershipId: id, searchStr });
    const options = response?.data?.lines?.map((line: any) => ({
      value: line.id,
      label: `${line.name} (${line.phone_number})`,
    }));

    return options;
  } catch (error) {
    console.error("Error fetching dealership data:", error);
    return [];
  }
};

export const priorityMapping: Record<number, string> = {
  0: "unread_messages",
  1: "new_leads",
  2: "engaged_leads",
  3: "appointment_confirmations",
  4: "priority_one_follow_up",
  5: "priority_two_follow_up",
  6: "priority_three_follow_up",
};
