import {
  Box,
  Button,
  HStack,
  Image,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import DOMPurify from "dompurify";
import Pusher from "pusher-js";
import { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import translationButtonImage from "../../assets/images/translationButtonImage.png";

import { SERVICES } from "../../config";
import {
  loadMoreMessages,
  sendMessage,
  sendMessageWithAttachment,
  translatemsg,
} from "../../utils/api/social-talk.api";
import { useUserInfo } from "../../utils/hooks/useUserInfo";
import MessengerAttachmentBar from "../AttachmentBar/MessengerAttachmentBar";
import { Input } from "../common";
import AttachmentViewer from "../common/AttachmentViewer";
import Loader from "../common/Spinner";
import { SendMsgIcon } from "../icons/svg";
import { formatTimestamp } from "../../utils";

export default function MessengerThread({
  selectedConversation,
  setConversationsItem,
  updateCurrentConvo,
}: any) {
  const toast = useToast();
  const [hasMore, setHasMore] = useState<any>(
    !!selectedConversation?.messages?.paging?.next
  );
  const [translatedArray, setTranslatedArray] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState(
    selectedConversation?.messages?.paging?.next
  );
  const dealership_id = useUserInfo("dealership")?.id;
  const dealership_name = useUserInfo("dealership")?.name;
  const user_name = useUserInfo("full_name");

  const token = useUserInfo("token");
  const [message, setMessage] = useState(
    selectedConversation?.messages?.items ?? []
  );
  const [attachmentUrls, setAttachmentUrls] = useState<any>([]);
  const [messagebody, setMessageBody] = useState("");
  const containerRef = useRef<any>(null);
  const dynamicValues: Record<any, string> = {
    "[FirstName]": selectedConversation?.first_name,
    "[LastName]": selectedConversation?.last_name,
    "[FullName]":
      selectedConversation?.first_name + " " + selectedConversation?.last_name,
    "[AccountName]": dealership_name,
    "[AgentName]": user_name,
  };
  const mutation = useMutation(sendMessage, {
    onSuccess: (data) => {
      if (data?.data?.success === true) {
        setMessage((prevMessages: any) => [
          ...prevMessages,
          data?.data?.message,
        ]);
        setMessageBody("");
        scrollToBottom();
      } else {
        toast({
          description: data?.data?.message ?? "Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    },
    onError: (error) => {
      toast({
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const translateMutation = useMutation(translatemsg, {
    onSuccess: (data, variables) => {
      const messageId = variables?.msg_id;
      setTranslatedArray((prev: any) => [
        ...prev.filter((item: any) => item.id !== messageId),
        { id: messageId, translated: data?.data?.translated_text },
      ]);
    },
    onError: (error) => {
      toast({
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  const translateMessageCall = (id: any, text: any) => {
    translateMutation.mutate({
      id: dealership_id,
      text: text,
      msg_id: id,
    });
  };
  const attachmentMutation = useMutation(sendMessageWithAttachment, {
    onSuccess: (data) => {
      if (data?.data?.success === true) {
        setMessage((prevMessages: any) => [
          ...prevMessages,
          data?.data?.message,
        ]);
        setAttachmentUrls([]);
        scrollToBottom();
      } else {
        toast({
          description: "Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    },
    onError: (error) => {
      toast({
        description: "Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    },
  });
  useEffect(() => {
    scrollToBottom();
  }, [message, selectedConversation]);
  const scrollToBottom = () => {
    if (containerRef?.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      setMessage(selectedConversation?.messages?.items);
      setHasMore(!!selectedConversation?.messages?.paging?.next);
      setNextUrl(selectedConversation?.messages?.paging?.next);
    }
  }, [selectedConversation]);

  const fetchMoreData = async () => {
    try {
      const encodedLink = encodeURIComponent(nextUrl);
      const response = await loadMoreMessages({ dealership_id, encodedLink });
      setMessage((prevMessages: any) => [
        ...response?.data?.data?.messages?.items,
        ...prevMessages,
      ]);
      setHasMore(!!response?.data?.data?.messages?.paging?.next);
      setNextUrl(response?.data?.data?.messages?.paging?.next);
    } catch (error) {
      console.error("Error fetching dealership data:", error);
      return [];
    }
  };
  useEffect(() => {
    const pusher = new Pusher(SERVICES.PUSHER_APP_KEY, {
      cluster: SERVICES.PUSHER_APP_CLUSTER,
      authEndpoint: `${SERVICES.apiBaseUrl}/p/broadcasting/auth`,
      auth: {
        headers: {
          // Add any headers you need for your auth endpoint, e.g., auth tokens
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          // "Content-Type": "application/json",
        },
      },
    });

    const channel = pusher.subscribe(
      `private-fb-message-received-${dealership_id}`
    );
    channel.bind("App\\Events\\FacebookMessenger", function (payload: any) {
      if (payload?.data?.messages?.items) {
        if (
          selectedConversation?.conversation_id ===
          payload?.data?.conversation_id
        ) {
          setMessage((prevMessages: any) => {
            const messageMap = new Map(
              prevMessages.map((msg: any) => [msg.id, msg])
            );
            payload.data.messages.items.forEach((msg: any) => {
              if (!messageMap.has(msg.id)) {
                messageMap.set(msg.id, msg);
              } else {
                console.log(`Duplicate message detected`);
              }
            });

            return Array.from(messageMap.values());
          });
          updateCurrentConvo(payload?.data);
          scrollToBottom();
        } else {
          setConversationsItem(payload?.data);
        }
      }
    });

    // Cleanup on unmount
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, []);
  const data = {
    variables: [
      {
        name: `[FirstName] to include contact's first name`,
        value: "[FirstName]",
      },
      {
        name: `[LastName] to include contact's last name`,
        value: "[LastName]",
      },
      {
        name: `[FullName] to include contact's full name`,
        value: "[FullName]",
      },
      {
        name: `[AccountName] to include contact's company name`,
        value: "[AccountName]",
      },
      {
        name: `[AgentName] to include contact's agent name`,
        value: "[AgentName]",
      },
    ],
  };
  const replacePlaceholders = (message: any) => {
    let updatedMessage = message;
    data.variables.forEach((variable: any) => {
      const { value } = variable;
      if (message.includes(value)) {
        updatedMessage = updatedMessage.replace(value, dynamicValues[value]);
      }
    });
    return updatedMessage;
  };
  const sendMessageCall = (type?: any, inventory_id?: any) => {
    mutation.mutate({
      id: dealership_id,
      data: {
        conversation_id: selectedConversation?.conversation_id,
        message: !type ? replacePlaceholders(messagebody) : undefined,
        message_type: type ? type : "text",
        inventory_id: type === "car-template" ? inventory_id : undefined,
        recipient_id: selectedConversation?.id,
        first_name:
          type === "car-template"
            ? selectedConversation?.first_name
            : undefined,
        last_name:
          type === "car-template" ? selectedConversation?.last_name : undefined,
      },
    });
  };

  const sendAttachmentMessageCall = (type?: any, url?: any) => {
    attachmentMutation.mutate({
      id: dealership_id,
      conversation_id: selectedConversation?.conversation_id,
      url: url ? url : attachmentUrls[0]?.url,
      type: type ? type : "audio",
      recipient_id: selectedConversation?.id,
    });
  };

  const handleSubmit = () => {
    if (messagebody !== "") {
      sendMessageCall();
    }
    if (attachmentUrls?.length > 0) {
      sendAttachmentMessageCall();
    }
  };

  // translation
  const [visibleSelectId, setVisibleSelectId] = useState<string | null>(null);
  const [selectedTranslation, setSelectedTranslation] = useState<string | null>(
    null
  );

  const toggleSelectVisibility = (id: string) => {
    setVisibleSelectId(visibleSelectId === id ? null : id);
  };

  const options = [
    { label: "To English", value: "english" },
    { label: "To Spanish", value: "spanish" },
  ];

  return (
    <>
      {selectedConversation ? (
        <VStack w="100%" gap="0" position="relative" h="100%">
          <HStack
            w="100%"
            justifyContent="space-between"
            padding="1.44rem"
            borderBottom="1px solid var(--grey-300)"
          >
            <Text textStyle="h3" fontWeight="500">
              {selectedConversation?.first_name +
                " " +
                selectedConversation?.last_name}
            </Text>
          </HStack>{" "}
          <VStack w="100%" gap="1rem" flexGrow={1}>
            <div
              id="scrollableDiv"
              style={{
                height: "calc(100vh - 500px)",
                overflow: "auto",
                // maxWidth: "910px",
                width: "100%",
                display: "flex",
                flexDirection: "column-reverse",
              }}
            >
              <InfiniteScroll
                dataLength={message.length}
                next={fetchMoreData}
                hasMore={hasMore}
                inverse={true}
                loader={""}
                scrollableTarget="scrollableDiv"
              >
                <VStack
                  alignItems="flex-start"
                  ref={containerRef}
                  w="100%"
                  // h="calc(100vh - 500px)"
                  overflowY="auto"
                  marginBottom="auto"
                  gap="0.96rem"
                  padding="1.3rem"
                >
                  {message?.map((item: any, index: any) => (
                    <VStack
                      key={item.id}
                      w="100%"
                      gap="0.25"
                      maxW="22rem"
                      mt="0.75rem"
                      // maxW="50rem"
                      alignSelf={
                        item?.direction === 0 ? "flex-end" : "flex-start"
                      }
                    >
                      <HStack w="100%" justifyContent="space-between">
                        <Text
                          fontSize="0.71rem"
                          color="var(--grey-700)"
                          fontWeight="500"
                        >
                          {formatTimestamp(item?.time)}
                        </Text>
                        {item?.direction === 1 && (
                          <Text
                            fontSize="0.83rem"
                            color="var(--grey-900)"
                            fontWeight="400"
                          >
                            {selectedConversation?.first_name +
                              " " +
                              selectedConversation?.last_name}
                          </Text>
                        )}
                      </HStack>
                      <VStack
                        alignItems="flex-start"
                        width="100%"
                        padding="0.96rem 1.32rem 0.67rem 0.9rem"
                        background={
                          item?.direction === 0
                            ? "var(--grey-100)"
                            : "var(--secondary-50)"
                        }
                        alignSelf={item?.direction === 1 ? "baseline" : "end"}
                        borderRadius="0.5rem"
                      >
                        <Text
                          fontSize="0.83rem"
                          color="var(--grey-900)"
                          fontWeight="500"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item?.body),
                          }}
                        ></Text>
                        {item?.body?.length > 0 && (
                          <VStack w="100%" alignItems="flex-start">
                            {!translatedArray?.find(
                              (items: any) => items?.id == item?.id
                            ) ? (
                              <HStack justifyContent="flex-end" w="100%">
                                <Button
                                  variant="none"
                                  onClick={() => {
                                    translateMessageCall(item.id, item.body);
                                  }}
                                  bg="#1e88e5"
                                >
                                  <Image
                                    src={translationButtonImage}
                                    alt="translate"
                                    w="30px"
                                  />
                                </Button>
                              </HStack>
                            ) : (
                              <>
                                <Text
                                  fontSize="0.74rem"
                                  color="var(--grey-900)"
                                  fontWeight="700"
                                  style={{ lineBreak: "anywhere" }}
                                  fontStyle="bold"
                                >
                                  Translated Text:
                                </Text>
                                <Text
                                  fontSize="0.73rem"
                                  color="var(--grey-600)"
                                  fontWeight="500"
                                  // style={{ lineBreak: "anywhere" }}
                                  fontStyle="italic"
                                >
                                  {
                                    translatedArray?.find(
                                      (items: any) => items?.id == item?.id
                                    )?.translated
                                  }
                                </Text>{" "}
                              </>
                            )}
                          </VStack>
                        )}
                      </VStack>
                    </VStack>
                  ))}
                </VStack>
              </InfiniteScroll>
            </div>
          </VStack>
          <VStack
            w="100%"
            alignItems="flex-start"
            gap="0"
            position="absolute"
            bottom="0"
            left="0"
          >
            <MessengerAttachmentBar
              setBody={(text, concatText, type = "text") => {
                if (type === "text") {
                  const messagesText = `${messagebody} ${
                    concatText ? `${concatText}: ` : ""
                  }${text}`;
                  setMessageBody(messagesText);
                } else {
                  setAttachmentUrls((prev: any) => [...prev, text]);
                }
              }}
              apiCall={sendMessageCall}
              attachmentApiCall={sendAttachmentMessageCall}
              handleTemplate={(value: string) => setMessageBody(value)}
            />
            <AttachmentViewer
              attachments={attachmentUrls}
              setAttachments={setAttachmentUrls}
            />
            <HStack w="100%" padding="0.75rem 1.44rem">
              <Input
                isTextarea={true}
                placeholder="Type your message here"
                w="100%"
                border="none"
                verticalAlign="center"
                paddingTop="33px"
                paddingLeft="0"
                sx={{
                  _focusVisible: {
                    outline: "none",
                  },
                }}
                value={messagebody}
                onChange={(e) => setMessageBody(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit();
                  }
                }}
              />
              <Button
                variant="none"
                padding="1rem"
                borderRadius="0.5rem"
                background="var(--primary-600)"
                onClick={handleSubmit}
              >
                <SendMsgIcon />
              </Button>
            </HStack>
          </VStack>
        </VStack>
      ) : (
        <>Select a convo to load</>
      )}
      {(mutation?.isLoading || attachmentMutation?.isLoading) && <Loader />}
    </>
  );
}
