export type DayHours = {
  end_time: string;
  day_status: boolean;
  start_time: string;
};

export type BusinessHours = {
  monday: DayHours;
  tuesday: DayHours;
  wednesday: DayHours;
  thursday: DayHours;
  friday: DayHours;
  saturday: DayHours;
  sunday: DayHours;
};

export const convertToMilitaryTime = (time: string): string => {
  if (!time) return "";
  const [timePart, modifier] = time?.split(/(am|pm)/i);
  let [hours, minutes] = timePart?.split(":").map(Number);

  // hours = (hours % 12) + (modifier.toLowerCase() === "pm" ? 12 : 0);
  hours =
    hours === 12 && modifier.toLowerCase() === "pm"
      ? 12
      : hours === 12 && modifier.toLowerCase() === "am"
      ? 0
      : hours;
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}`;
};

export const generateHalfHourSlots = (
  startDateTime: Date,
  endDateTime: Date
): string[] => {
  const slots: string[] = [];
  for (
    let current = new Date(startDateTime);
    current < endDateTime;
    current.setMinutes(current.getMinutes() + 30)
  ) {
    const hours = current.getHours();
    const minutes = current.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    const formattedHour = hours % 12 || 12;
    const formattedMinutes = minutes === 0 ? "00" : minutes;
    const slotTime = `${formattedHour}:${formattedMinutes} ${amPm}`;
    slots.push(slotTime);
  }

  return slots;
};
export const daysOfWeek: (keyof BusinessHours)[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const pickSlotTimes = (
  businessHours: BusinessHours,
  date: Date
): string[] => {
  const daySchedule = businessHours[daysOfWeek[date.getDay()]];

  if (!daySchedule?.day_status) return [];

  const { start_time: startTime, end_time: endTime } = daySchedule;

  if (!startTime || !endTime) return [];

  const [startHour, startMinute] = startTime.split(":").map(Number);
  const [endHour, endMinute] = endTime.split(":").map(Number);

  const startDateTime = new Date(date.setHours(startHour, startMinute, 0, 0));
  const endDateTime = new Date(date.setHours(endHour, endMinute, 0, 0));

  return generateHalfHourSlots(startDateTime, endDateTime);
};
